@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.label {
  color: #3175CA;
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 18.05px;
  vertical-align: top;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.trigger {
    font-size: 18px;
    padding: 0 24px;
    cursor: pointer;
    transition: color .3s;
  }
  
  .trigger:hover {
    color: #ffffff;
  }

  .logo-invoice {
    height: 2.5rem;
    width: 5rem;
  }
  .ant-menu-horizontal > .ant-menu-item-selected > a{
    color: #113c71 !important;
    font-weight: bold !important;
  }
  .ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected{
    border-bottom: none !important;
  }

  
.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: #1890ff;
  }
  
.link-button:hover,
.link-button:focus {
  text-decoration: none;
  }
.button-new {
    margin-bottom: 1rem;   
}
.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: #1890ff;
  }
  
.link-button:hover,
.link-button:focus {
  text-decoration: none;
  }
.button-new {
    margin-bottom: 1rem;
    
}
.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: #1890ff;
  }
  
.link-button:hover,
.link-button:focus {
  text-decoration: none;
  }
.ant-table td { white-space: nowrap; }
.button-new {
    margin-bottom: 1rem;
    
}

span.ant-typography.ticket-text-name {
    color: #1890ff !important;
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: #1890ff;
  }
  
.link-button:hover,
.link-button:focus {
  text-decoration: none;
  }
.header-logo {
    width: 50%;
    margin: auto;
    display: block;
}

.side-logo{
    width: 25%;
}
.login-form > button{
 margin:.3rem 0;
}

.company-card {
    margin: 0 auto;
    max-width: 38rem;
}

.forgot-password-link{
    color: #90897b;
}

.login-text-button{
    vertical-align: middle;
    font-weight: bold;
}

.login-icon-button{
    font-size: 22px;
    vertical-align: middle;
    font-weight: bold;
}
.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: #1890ff;
  }
  
.link-button:hover,
.link-button:focus {
  text-decoration: none;
  }
.ant-table td { white-space: nowrap; }
.sales_internal {
    background-color: lightsteelblue;
}
.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: #1890ff;
    text-decoration: underline;
  }
  
.link-button:hover,
.link-button:focus {
  text-decoration: none;
  }
.ant-table td { white-space: nowrap; }
.sales_internal {
    background-color: lightsteelblue;
}
.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: #1890ff;
  }
  
.link-button:hover,
.link-button:focus {
  text-decoration: none;
  }
.ant-table td { white-space: nowrap; }
.sales_internal {
    background-color: lightsteelblue;
}
.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: #1890ff;
  }
  
.link-button:hover,
.link-button:focus {
  text-decoration: none;
  }
.header-logo {
    width: 50%;
    margin: auto;
    display: block;
}

.side-logo{
    width: 25%;
}
.login-form > button{
 margin:.3rem 0;
}

.company-card {
    margin: 0 auto;
    max-width: 38rem;
}

.forgot-password-link{
    color: #90897b;
}

.login-text-button{
    vertical-align: middle;
    font-weight: bold;
}

.login-icon-button{
    font-size: 22px;
    vertical-align: middle;
    font-weight: bold;
}
.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: #1890ff;
  }
  
.link-button:hover,
.link-button:focus {
  text-decoration: none;
  }

.privacy{
      display: contents;
  }
.ant-table td { white-space: nowrap; }
.sales_internal {
    background-color: lightsteelblue;
}
.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: #1890ff;
  }
  
.link-button:hover,
.link-button:focus {
  text-decoration: none;
  }
.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: #1890ff;
  }
  
.link-button:hover,
.link-button:focus {
  text-decoration: none;
  }
.header-logo {
    width: 50%;
    margin: auto;
    display: block;
}

.side-logo{
    width: 25%;
}
.login-form > button{
 margin:.3rem 0;
}

.login-card {
    margin: auto;
    max-width: 30rem;
}

.ant-card.login-card{
    top: 2rem;
}
.password-card {
    margin: 0 auto;
    max-width: 38rem;
}

.ant-card.password-card{
    top: 6rem;
}


.forgot-password-link{
    color: #90897b;
}

.login-text-button{
    vertical-align: middle;
    font-weight: bold;
}

.login-icon-button{
    font-size: 22px;
    vertical-align: middle;
    font-weight: bold;
}
.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: #1890ff;
  }
  
.link-button:hover,
.link-button:focus {
  text-decoration: none;
  }
.header-logo {
    width: 50%;
    margin: auto;
    display: block;
}

.side-logo{
    width: 25%;
}
.login-form > button{
 margin:.3rem 0;
}

.activate-card {
    margin: 0 auto;
    max-width: 38rem;
}

.ant-card.activate-card{
    top: 6rem;
}

.forgot-password-link{
    color: #90897b;
}
.style-display-none
{
    display: none;
}
.style-text-align-center{
    text-align: center;
}
