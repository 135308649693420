.trigger {
    font-size: 18px;
    padding: 0 24px;
    cursor: pointer;
    transition: color .3s;
  }
  
  .trigger:hover {
    color: #ffffff;
  }

  .logo-invoice {
    height: 2.5rem;
    width: 5rem;
  }
  .ant-menu-horizontal > .ant-menu-item-selected > a{
    color: #113c71 !important;
    font-weight: bold !important;
  }
  .ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected{
    border-bottom: none !important;
  }

  