.header-logo {
    width: 50%;
    margin: auto;
    display: block;
}

.side-logo{
    width: 25%;
}
.login-form > button{
 margin:.3rem 0;
}

.activate-card {
    margin: 0 auto;
    max-width: 38rem;
}

.ant-card.activate-card{
    top: 6rem;
}

.forgot-password-link{
    color: #90897b;
}
.style-display-none
{
    display: none;
}
.style-text-align-center{
    text-align: center;
}